// This script makes the module tabs keyboard accessible
const tabLinks = document.querySelectorAll('.pager');
const tabContents = document.querySelectorAll('.assessment');

tabLinks.forEach((tab, index) => {
    tab.addEventListener('click', (e) => {
        e.preventDefault();
        // Set the "active" class for the clicked tab
        tabLinks.forEach((t) => {
            t.classList.remove('active');
        });
        
        tab.classList.add('active');

        // Add the "active" class to the content div that matches the clicked tab
        tabContents.forEach((content) => {
            content.classList.remove('active');
        });

        tabContents[index].classList.add('active');
    });

    tab.addEventListener('keydown', (e) => {
        if ((e.key === 'ArrowRight') && index < tabLinks.length - 1) {
            tabLinks[index + 1].focus();
        } else if ((e.key === 'ArrowLeft') && index > 0) {
            tabLinks[index - 1].focus();
        } else if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            tab.click();
        }
    });
});